<template>
  <div>
    <el-row>
      <div class="wraper" v-wechat-title="this.goodsinfo.name+ '-3A医药城'">
        <ShopHead :productName="productName"></ShopHead>
        <SetMealGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo"></SetMealGalss>
        <SetMealInfo
          class="info"
          v-if="renderFlag"
          :goodsinfo="goodsinfo"
        ></SetMealInfo>
      </div>
    </el-row>
    <el-row>
      <el-col :span="24" >
        <p class="setMealCon_header">
          <span>
            套餐内商品
          </span>
        </p>
         <div class="setMealCon_main">
           <comboItem :productType="productType" v-for="(item,index) in goodsinfo.productVOList" :key="index" :info="item"></comboItem>
         </div>
      </el-col>
     
    </el-row>
  </div>
</template>

<script>
const comboItem=()=>import('components/index/comboItem.vue');
import {   SetMealDetail} from "api/activity.js";
// import Qs from 'qs'
const SetMealGalss = () => import("components/product/SetMealGalss.vue");
const SetMealInfo = () => import("components/product/SetMealInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
export default {
  name: "SetMealDetail",
   inject:['reload'],
  data() {
    return {
      productType:'setMeal',
      productName:'超值套餐',
      renderFlag: false,
      goodsinfo: "",
    };
  },
  components: {
    SetMealGalss,
    SetMealInfo,
    ShopHead,
    comboItem
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.productId": function() {
        this.reload();
    },
  },
  created() {
    this.getProductDetail();
  },
  methods: {
    
    getProductDetail() {
      const parmes = this.$route.query;
       // 显示loading
     this.$loading({ fullscreen: true ,background:'#ffffff45'})

    //  console.log(parmes)
      SetMealDetail(parmes).then((res) => {
        // //console.log(res.data.data);
        if(res.data.code==400){
          this.$message.error(res.data.msg);
          let that=this
          setTimeout(function(){that.$router.push({name:"Home"})},500);
        }
        this.productName=res.data.data.name
        this.goodsinfo = res.data.data;
        this.renderFlag = true;
         // 关闭loading
          this.$loading().close();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.el_main {
  background: #fff;
}
.wraper {
  margin-top: 20px;
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 458px);
  }
  // .detailInfo {
  //   width: 1200px;
  // }
}
.setMealCon_header{
   font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    span {
      background: #ff3333;
      color: #fff;
      font-size: 16px;
      display: inline-block;
      width: 120px;
      text-align: center;
      line-height: 50px;
    }
}
.setMealCon_main{
   box-sizing: content-box;
    padding:0;
    margin-top: 30px;
    margin-bottom: 30px;
    float:left;
    width:100% ;
}
</style>

